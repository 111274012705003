// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

document.addEventListener('turbo:load', () => {
  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });

  $('.popup__mask').on('click', (e) => {
    if (e.target != e.currentTarget && !$(e.target).hasClass('popup__close-button-x')) return;
    e.stopPropagation();
    $iframe = $(e.currentTarget).hide().find('iframe');
    src = $iframe.attr('src');
    $iframe.attr('src', ''); // stop the video if it's playing
    $iframe.attr('src', src);
  });

  $('.episode__link').on('click', (e) => {
    e.stopPropagation();
    $(e.currentTarget).nextAll('.popup__mask').show();
  });

});
